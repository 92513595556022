// extracted by mini-css-extract-plugin
export var badgeInactive = "ResourceDetail__badgeInactive__AZXS9";
export var badgeWrapper = "ResourceDetail__badgeWrapper__KRx_4";
export var breadcrumbContainer = "ResourceDetail__breadcrumbContainer__QHrsX";
export var breadcrumbText = "ResourceDetail__breadcrumbText__R84SL";
export var breadcrumbWrapper = "ResourceDetail__breadcrumbWrapper__vrReF";
export var column = "ResourceDetail__column__wlt0_";
export var container = "ResourceDetail__container__ZO0iv";
export var contentContainer = "ResourceDetail__contentContainer__tMdXz";
export var detailText = "ResourceDetail__detailText__KcEMQ";
export var downloadButton = "ResourceDetail__downloadButton__haW2q";
export var fileContainer = "ResourceDetail__fileContainer__poYGR";
export var flex = "ResourceDetail__flex__BDdaT";
export var flexColumn = "ResourceDetail__flexColumn__PO96T";
export var gap1 = "ResourceDetail__gap1__YD77f";
export var gap2 = "ResourceDetail__gap2__AVf_c";
export var gap3 = "ResourceDetail__gap3__ZlS1r";
export var gap4 = "ResourceDetail__gap4__Gmwlt";
export var gap5 = "ResourceDetail__gap5__lkzAF";
export var grid = "ResourceDetail__grid__unNYt";
export var lineSeperator = "ResourceDetail__lineSeperator__aGwGn";
export var mobileHiddenImageContainer = "ResourceDetail__mobileHiddenImageContainer__Xb8y2";
export var mobileImage = "ResourceDetail__mobileImage__nxSjT";
export var mobileImageContainer = "ResourceDetail__mobileImageContainer__MzEOG";
export var resourceTitle = "ResourceDetail__resourceTitle__BWQ0m";
export var row = "ResourceDetail__row__Fq0E6";
export var sideColumn = "ResourceDetail__sideColumn__fhBTW";
export var summaryText = "ResourceDetail__summaryText__WxNze";
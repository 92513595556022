// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as H1 from "../../../styleguide/components/Heading/H1.res.js";
import * as Api from "../../../api/Api.res.js";
import * as Col from "../../../styleguide/components/Grid/Col/Col.res.js";
import * as Row from "../../../styleguide/components/Grid/Row/Row.res.js";
import * as Url from "../../../utils/Url.res.js";
import * as Grid from "../../../styleguide/components/Grid/Grid.res.js";
import * as User from "../../../models/User.res.js";
import * as React from "react";
import * as Button from "../../../styleguide/components/Button/Button.res.js";
import * as $$Promise from "../../../bindings/Promise.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Breadcrumb from "../../../styleguide/components/Breadcrumb/Breadcrumb.res.js";
import * as ResourceItem from "../../../models/ResourceItem.res.js";
import * as SentryLogger from "../../../loggers/SentryLogger.res.js";
import * as Routes_Resource from "../../../routes/common/Routes_Resource.res.js";
import * as ResourceCategory from "../../../models/ResourceCategory.res.js";
import * as ResourcesLanding from "../resource-landing/ResourcesLanding.res.js";
import * as DownloadResources from "../download-resources/DownloadResources.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as SignInModalContainer from "../../../styleguide/modals/SignInModal/SignInModalContainer.res.js";
import * as ResourceDetailScss from "./ResourceDetail.scss";

var css = ResourceDetailScss;

function addBreadcrumb(breadcrumbList, linkName, linkPath) {
  var breadcrumb = {
    linkName: linkName,
    linkPath: linkPath
  };
  breadcrumbList.push(breadcrumb);
}

function generateBreadcrumbs(categoryDetail, resourceDetail) {
  var breadcrumbs = [];
  var categoryPath = "/resources/" + categoryDetail.slug;
  var resourceSpecificPath = categoryPath + ("/" + resourceDetail.slug);
  addBreadcrumb(breadcrumbs, "Home", "/");
  addBreadcrumb(breadcrumbs, "Resources", "/resources");
  addBreadcrumb(breadcrumbs, categoryDetail.navigationName, categoryPath);
  addBreadcrumb(breadcrumbs, resourceDetail.title, resourceSpecificPath);
  return breadcrumbs;
}

function notifyAndDownload(resourceDetail) {
  $$Promise.wait(Api.notifyResourceDownload(resourceDetail), (function (x) {
          if (x.TAG !== "Ok") {
            return SentryLogger.error1({
                        rootModule: "ResourceDetail",
                        subModulePath: {
                          hd: "ResourceDetail",
                          tl: /* [] */0
                        },
                        value: "notifyAndDownload",
                        fullPath: "ResourceDetail.ResourceDetail.notifyAndDownload"
                      }, "ResourceDetail" + "::ResourceDetail::notifyDownload::Error", [
                        "Error",
                        x._0
                      ]);
          }
          Url.visitBlank(resourceDetail.privateDownload);
          Url.visit(Routes_Resource.downloadThanks(resourceDetail.title));
        }));
}

var initialState = {
  signInModal: "Hidden"
};

function reducer(_state, action) {
  return {
          signInModal: action._0
        };
}

function ResourceDetail$ResourceDetail(props) {
  var userLoginStatus = props.userLoginStatus;
  var resourceDetail = props.resourceDetail;
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var match$1 = match[0].signInModal;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(Breadcrumb.make, {
                      breadcrumbLinks: generateBreadcrumbs(ResourcesLanding.Helpers.getDetails(props.selectedCategory, props.categories), resourceDetail),
                      wrapperContainerClassName: css.breadcrumbContainer,
                      breadcrumbLinkClassName: css.breadcrumbText
                    }),
                JsxRuntime.jsx(Grid.make, {
                      className: css.grid,
                      children: JsxRuntime.jsxs(Row.make, {
                            className: css.row,
                            children: [
                              JsxRuntime.jsxs(Col.make, {
                                    className: css.contentContainer,
                                    md: 8,
                                    lg: 8,
                                    children: [
                                      resourceDetail.active ? null : JsxRuntime.jsx("div", {
                                              children: JsxRuntime.jsx("button", {
                                                    children: "Inactive",
                                                    className: css.badgeInactive,
                                                    disabled: true
                                                  }),
                                              className: css.badgeWrapper
                                            }),
                                      JsxRuntime.jsx(H1.make, {
                                            className: css.resourceTitle,
                                            children: resourceDetail.title
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: JsxRuntime.jsx("img", {
                                                  className: css.mobileImage,
                                                  src: resourceDetail.resourceImage
                                                }),
                                            className: css.mobileImageContainer
                                          }),
                                      JsxRuntime.jsx("p", {
                                            children: resourceDetail.resourceSummary,
                                            className: css.summaryText
                                          }),
                                      JsxRuntime.jsx("div", {
                                            className: Cx.cx([
                                                  css.detailText,
                                                  "text-gray",
                                                  "mt-4"
                                                ]),
                                            dangerouslySetInnerHTML: {
                                              __html: resourceDetail.resourceDetail
                                            }
                                          }),
                                      JsxRuntime.jsxs("div", {
                                            children: [
                                              JsxRuntime.jsx(Button.make, {
                                                    size: "MD",
                                                    color: "Teal",
                                                    disabled: resourceDetail.pdfFile === "",
                                                    visuallyDisabled: resourceDetail.pdfFile.length === 0,
                                                    onClick: (function (param) {
                                                        if (userLoginStatus === "LoggedIn") {
                                                          return notifyAndDownload(resourceDetail);
                                                        } else {
                                                          return dispatch({
                                                                      TAG: "ToggleSignInModal",
                                                                      _0: "Shown"
                                                                    });
                                                        }
                                                      }),
                                                    children: "Download"
                                                  }),
                                              props.isAdmin ? JsxRuntime.jsx(Button.make, {
                                                      size: "MD",
                                                      color: "White",
                                                      onClick: (function (_e) {
                                                          Url.visit(Routes_Resource.Dashboard.edit(resourceDetail.id));
                                                        }),
                                                      children: "Edit Resource"
                                                    }) : null
                                            ],
                                            className: css.fileContainer
                                          })
                                    ]
                                  }),
                              JsxRuntime.jsxs(Col.make, {
                                    className: css.sideColumn,
                                    md: 4,
                                    lg: 4,
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: JsxRuntime.jsx("img", {
                                                  src: resourceDetail.resourceImage
                                                }),
                                            className: css.mobileHiddenImageContainer
                                          }),
                                      JsxRuntime.jsx(DownloadResources.make, {})
                                    ]
                                  })
                            ]
                          })
                    }),
                match$1 === "Shown" ? JsxRuntime.jsx(SignInModalContainer.make, {
                        close: (function () {
                            dispatch({
                                  TAG: "ToggleSignInModal",
                                  _0: "Hidden"
                                });
                          }),
                        callback: (function () {
                            
                          }),
                        setUserData: props.setUserData,
                        subTitle: "Sign In to download: " + resourceDetail.title
                      }) : null
              ],
              className: css.container
            });
}

var ResourceDetail = {
  css: css,
  addBreadcrumb: addBreadcrumb,
  generateBreadcrumbs: generateBreadcrumbs,
  notifyAndDownload: notifyAndDownload,
  initialState: initialState,
  reducer: reducer,
  make: ResourceDetail$ResourceDetail
};

function ResourceDetail$default(props) {
  var props$1 = props.props;
  return JsxRuntime.jsx(ResourceDetail$ResourceDetail, {
              categories: Belt_Array.map(props$1.categories, (function (category) {
                      return ResourceCategory.fromJs(category);
                    })),
              resourceDetail: ResourceItem.fromJs(props$1.resourceDetail),
              selectedCategory: props$1.selectedCategory,
              userLoginStatus: User.LoginStatus.fromBool(props.reduxProps.isUserLoggedIn),
              setUserData: props.reduxfunction.setUserData,
              isAdmin: props$1.isAdmin
            });
}

var $$default = ResourceDetail$default;

export {
  ResourceDetail ,
  $$default as default,
}
/* css Not a pure module */
